import { useState, useEffect, useRef } from 'react';

import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

const genName = () => Date.now() + '-+-' + Math.random() * 1000000;

export function TextInput(props) {
  const { onFieldChange, onFieldFocus, onFieldBlur, key, label, value, required, error, showErrors } = props;

  const [hasBeenTouched, setHasBeenTouched] = useState(!!value);
  const [uniqueFieldName, setUniqueFieldName] = useState(genName());

  const [cursorPosition, setCursorPosition] = useState(0);

  const $input = useRef(null);

  const _change = e => {
    setCursorPosition(e.target.selectionStart);
    onFieldChange(e.target.value);

    typeof props.onChange === 'function' && props.onChange(e);
  };

  const _focus = e => {
    onFieldFocus();

    typeof props.onFocus === 'function' && props.onFocus(e);
  };

  const _blur = e => {
    onFieldBlur();

    typeof props.onBlur === 'function' && props.onBlur(e);

    setHasBeenTouched(true);
  };

  useEffect(() => {
    setUniqueFieldName(genName());
  }, [key]);

  useEffect(() => {
    if (!$input.current) return;

    $input.current.selectionStart = cursorPosition;
    $input.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  const shouldShowErrors = showErrors && hasBeenTouched && (value || required) && error;

  return (
    <div className='core-text-input'>
      {value && (
        <label>
          {label}
          {required ? <span>*</span> : ''}
        </label>
      )}

      <input
        ref={$input}
        name={uniqueFieldName}
        autoComplete='new-password'
        type='text'
        value={value || ''}
        placeholder={label + (required ? ' *' : '')}
        onChange={_change}
        onFocus={_focus}
        onBlur={_blur}
      />

      {shouldShowErrors && <span>{error}</span>}
    </div>
  );
}

const scope = '.core-text-input';
const styles = `
  {
    position: relative;
    width: 100%;
    font-preset: light;
    margin: 0 0 conf(spacing, small) 0;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    font-preset: 1em light;
    color: conf(color, primary);
  }

  label span {
    color: conf(color, warn);
    padding: 0 2px;
  }

  input {
    padding: 2em 0 conf(spacing, smaller) 0;
    border: none;
    outline: none;
    width: 100%;
    color: conf(color, darkerGrey);
    border-bottom: 2px solid conf(color, lightGrey);
  }

  input:focus {
    border-bottom: 2px solid conf(color, primary);
  }

  input::placeholder {
    color: conf(color, grey);
  }

  > span {
    font-preset: .8em light;
    color: conf(color, warn);
  }
`;

CustomStyleSheet.create(styles, scope);
