export const validateForm = (target, sections, state) => {
  const errors = {};
  let completedSectionFieldCount = 0;

  const targetLength = Math.min(target + 1, sections.length);

  for (let i = 0; i < targetLength; i++) {
    const section = sections[i];

    for (let j = 0; j < section.fields.length; j++) {
      const field = section.fields[j];
      const validate = field.validate;

      const error = validate(state);

      if (typeof error === 'string' && error) {
        errors[field.key] = error;
        continue;
      }

      if (target === i) completedSectionFieldCount++;
    }
  }

  return { errors };
};
