import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

export function ExpiredScreen() {
  document.title = 'docpay - Form expired';

  return (
    <div className='expired-screen'>
      <h1>Expired</h1>

      <p>This form has expired and is no longer accessible. If this is unexpected, please contact your doctor.</p>
    </div>
  );
}

const scope = '.expired-screen';
const styles = `
  {
    flexbox: column center center;
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  h1 {
    color: conf(color, warn);
    margin: 0;
    font-preset: 3em light;
    text-align: center;
    padding-bottom: conf(spacing, smallest);
  }

  p {
    color: conf(color, darkerGrey);
    font-preset: 1.2em light;
    text-align: center;
    margin: 0;
  }
`;

CustomStyleSheet.create(styles, scope);
