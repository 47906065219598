import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

export function NotFoundScreen() {
  document.title = 'docpay - Form not found';

  return (
    <div className='not-found-screen'>
      <h1>Not found.</h1>

      <p>This form could not be found. If this is unexpected, please contact your doctor and ask them for a new one.</p>
    </div>
  );
}

const scope = '.not-found-screen';
const styles = `
  {
    flexbox: column center center;
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  h1 {
    color: conf(color, primary);
    margin: 0;
    font-preset: 3em light;
    text-align: center;
    padding-bottom: conf(spacing, smallest);
  }

  p {
    color: conf(color, darkerGrey);
    font-preset: 1.2em light;
    text-align: center;
    margin: 0;
  }
`;

CustomStyleSheet.create(styles, scope);
