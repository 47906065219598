import { useEffect } from 'react';

import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import template from '@prahatech/util-react-web/utils/template';

export function FormField(props) {
  const { form, state, config } = props;
  const { key, type, label, required, when } = config || {};

  const passDown = { ...config, validate: undefined };

  const isUsed = when(state);

  let _html = !!config.html ? template(config.html, state.values) : undefined;

  useEffect(() => {
    if (isUsed) return;

    const field = form.field(key);
    setTimeout(() => field.change(undefined));
  }, [key, isUsed]);

  useEffect(() => {
    if (!isUsed || !required) return;

    setTimeout(() => {
      form.validate();
      form._.notifyListeners();
    });
  }, [key, isUsed]);

  const Field = form.Field;

  return isUsed ? (
    <div className='section-form-field'>
      <Field {...passDown} key={key} type={type} name={key} label={label} required={required} html={_html} />
    </div>
  ) : null;
}

const scope = '.section-form-field';
const styles = `
  {
    width: 100%;
  }
`;

CustomStyleSheet.create(styles, scope);
