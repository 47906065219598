import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

export function KaboomKapowScreen() {
  document.title = 'docpay - Error';

  return (
    <div className='kaboom-kapow-screen'>
      <h1>Something has gone wrong.</h1>

      <p>Please give our engineers some time to look into this issue and try again later.</p>
    </div>
  );
}

const scope = '.kaboom-kapow-screen';
const styles = `
  {
    flexbox: column center center;
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  h1 {
    color: conf(color, warn);
    margin: 0;
    font-preset: 1.6em light;
    text-align: center;
    padding-bottom: conf(spacing, smallest);
  }

  p {
    color: conf(color, darkerGrey);
    font-preset: 1em light;
    text-align: center;
    margin: 0;
  }
`;

CustomStyleSheet.create(styles, scope);
