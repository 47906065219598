import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import { useState } from 'react';

export function RadioInput(props) {
  const { onFieldChange, onFieldFocus, onFieldBlur, value, required, error, showErrors, label, options = [] } = props;

  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  const _setValue = (e, value) => {
    onFieldChange(value);

    _blur();

    typeof props.onChange === 'function' && props.onChange(e);
  };

  const _focus = e => {
    onFieldFocus();

    typeof props.onFocus === 'function' && props.onFocus(e);
  };

  const _blur = e => {
    onFieldBlur();

    typeof props.onBlur === 'function' && props.onBlur(e);

    value && setHasBeenTouched(true);
  };

  const _renderRadio = options => {
    const $result = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];

      $result.push(
        <div
          className={['radio', option === value && 'radio--active'].join(' ')}
          key={option}
          onClick={e => _setValue(e, option)}
          onFocus={_focus}
          onBlur={_blur}>
          <div className='radio__btn'>
            <div className='radio__btn__border'></div>
          </div>
          <div className='radio__label'>{option}</div>
        </div>
      );
    }

    return $result;
  };

  const shouldShowErrors = showErrors && hasBeenTouched && (value || required) && error;

  return (
    <div className='radio-input'>
      <div className='radio-input__question'>
        <div className='radio-input__label'>
          {label}
          {required ? <span>*</span> : ''}
        </div>
        <div className='radio-input__error'>{shouldShowErrors && <span>{error}</span>}</div>
      </div>

      <div className='radio__list'>{_renderRadio(options)}</div>
    </div>
  );
}

const scope = '.radio-input';
const styles = `
  {
    width: 100%;
    margin: 0 0 conf(spacing, small) 0;
  }

  .radio-input__label {
    color: conf(color, primary);
    font-preset: 1em light;
  }

  .radio-input__label span {
    color: conf(color, warn);
    padding: 0 2px;
  }

  .radio-input__error {
    font-preset: .8em light;
    color: conf(color, warn);
  }

  .radio {
    height: 22px;
    margin-top: 15px;
    padding-left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .radio__btn {
    width: 22px;
    height: 22px;
    background-color: conf(color, lightGrey);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .radio--active .radio__btn{
    background-color: conf(color, primary);
  }

  .radio__btn__border {
    width: 14px;
    height: 14px;
    border: 1px solid white;
    border-radius: 50%;
  }

  .radio__label {
    padding-left: 15px;
    font-size: 14px;
    color: conf(color, darkGrey)
  }
`;

CustomStyleSheet.create(styles, scope);

export default RadioInput;
