import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

export function NotExistScreen() {
  return (
    <div className='not-exist-screen'>
      <h1>This page does not exist.</h1>
    </div>
  );
}

const scope = '.not-exist-screen';
const styles = `
  {
    width: 100%;
    height: 100%;
  }

  h1 {
    color: #666;
    margin: 0;
    padding: 2ch;
    font-weight: 200;
  }
`;

CustomStyleSheet.create(styles, scope);
