import { useState, useEffect } from 'react';

import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import { Button } from '../../../core/components';
import { PaymentProvider } from '../../../providers';

export function PaymentSection({ formCode, heading, motivation, termsLink, amountCents, onComplete, onError } = {}) {
  const [mode, setMode] = useState('confirmation');
  const setConfirmationMode = () => setMode('confirmation');

  // Change Reference (CTRL-F for related changes): PF-FRAMES-01
  // const setPayingMode = () => setMode('paying');

  const [paymentUrl, setPaymentUrl] = useState(undefined);

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  // Change Reference (CTRL-F for related changes): PF-FRAMES-01
  // const [showFailureMessage, setShowFailureMessage] = useState(false);

  const _onPaymentSuccess = () => {
    onComplete && onComplete();
  };

  // Change Reference (CTRL-F for related changes): PF-FRAMES-01
  // const _onPaymentFailure = () => {
  //   setConfirmationMode();
  //   setShowFailureMessage(true);
  //   setPaymentUrl(undefined);
  // };

  const _checkStatus = async () => {
    if (document.hasFocus() && paymentUrl) {
      const paymentStatusResult = await PaymentProvider.fetchPaymentStatus(formCode);

      if (!paymentStatusResult.ok) {
        setConfirmationMode();
        onError && onError();
      }

      if (paymentStatusResult.ok) {
        const status = paymentStatusResult.payment.status.toLowerCase();
        const isPaid = status === 'paid';

        if (isPaid) _onPaymentSuccess();
      }
    }

    let keepLooping = true;

    if (keepLooping) setTimeout(_checkStatus, 5000);

    return () => {
      keepLooping = false;
    };
  };

  const _redirectToPaymentUrlInsteadOfIFrame = async paymentUrl => {
    window.location.assign(paymentUrl);
  };

  const _proceedToPayment = async () => {
    setIsLoadingPayment(true);

    const paymentResult = await PaymentProvider.fetchPayment(formCode);

    if (!paymentResult.ok) {
      setShowGenericError(true);
      setIsLoadingPayment(false);

      onError && onError();

      return;
    }

    const status = paymentResult.payment.status.toLowerCase();
    const isPaid = status === 'paid';

    if (isPaid) return onComplete && onComplete();

    const paymentUrl = paymentResult.payment.url;

    setIsLoadingPayment(false);
    setPaymentUrl(paymentUrl);

    // Change Reference (CTRL-F for related changes): PF-FRAMES-01
    // Payfast does not allow IFrames anymore. Instead of using the IFrame, we need to redirect the user to the payment page.
    // This obviously breaks a lot - we can not show the user success/failure based on the payment ITN outcome anymore.
    // Code used to be: setPayingMode();
    // Code is now:
    _redirectToPaymentUrlInsteadOfIFrame(paymentUrl);
  };

  useEffect(() => {
    const cancelStatusCheck = _checkStatus();

    return cancelStatusCheck;
  }, [formCode]);

  // Change Reference (CTRL-F for related changes): PF-FRAMES-01
  // useEffect(() => {
  //   const _onMessage = event => {
  //     event.preventDefault();
  //     event.stopPropagation();

  //     const message = event.data;

  //     if (!String(message).startsWith('docpay://')) return;

  //     const statusCallback = {
  //       'docpay://payment-status/success': _onPaymentSuccess,
  //       'docpay://payment-status/failure': _onPaymentFailure,
  //     }[message];

  //     statusCallback && statusCallback();
  //   };

  //   window.addEventListener('message', _onMessage);

  //   return () => {
  //     window.removeEventListener('message', _onMessage);
  //   };
  // }, [null]);

  let rands = String(amountCents).replace(/^(\d*)(\d{2})$/, '$1.$2');
  if (rands.startsWith('.')) rands = '0' + rands;

  return (
    <div className='payment-section'>
      {mode === 'confirmation' ? (
        <div className='payment-section__confirmation'>
          <h1 className='payment-section__heading'>{heading || 'Secure Your Appointment'}</h1>

          <div className='payment-section__explanation'>
            {motivation || 'Please make the following payment. The amount will be credited to your account.'}
          </div>

          <div className='payment-section__charge'>R {rands}</div>

          <div className='payment-section__terms'>
            {'Only Visa and Mastercard payments are accepted. Please ensure that you have read and understood our '}
            <a href={termsLink} target='_blank'>
              terms and conditions
            </a>
            {'.'}
          </div>

          <Button label='Pay now' onPress={_proceedToPayment} loading={isLoadingPayment} disabled={isLoadingPayment} />
        </div>
      ) : mode === 'paying' ? (
        <div className='payment-section__portal'>
          <iframe src={paymentUrl} />
        </div>
      ) : null}
    </div>
  );
}

const scope = '.payment-section';
const styles = `
  {
    flexbox: column center initial;
    width: 100%;
    padding: 0 conf(spacing, small);
  }

  .payment-section__confirmation {
    flexbox: column center initial;
    width: 100%;
    max-width: 600px;
    min-width: 300px;
  }

  .payment-section__heading {
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    margin: 0;
    padding: conf(spacing, small) 0 conf(spacing, smallest) 0;
    font-preset: 1.5em light;
    text-align: center;
    color: conf(color, primary);
  }

  .payment-section__explanation {
    padding-bottom: conf(spacing, small);
    color: conf(color, black);
    font-preset: 1em light;
    text-align: center;
    line-height: 1.5;
  }

  .payment-section__charge {
    color: conf(color, primary);
    font-preset: 1.5em light;
  }

  .payment-section__terms {
    padding-top: conf(spacing, medium);
    font-preset: .8em light;
    color: conf(color, black);
    text-align: center;
    line-height: 1.2;
  }

  .payment-section__terms a {
    color: conf(color, primary);
    cursor: pointer;
    border-bottom: 1px dotted conf(color, primary);
  }

  .payment-section__portal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .payment-section__portal iframe {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: transparent;
  }
`;

CustomStyleSheet.create(styles, scope);
