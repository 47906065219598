import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import { Button } from '../../../core/components';

export function Terms({ config = {}, onAccept } = {}) {
  const { title, doctor, paragraphs = [], terms } = config;

  const _onAccept = () => {
    typeof onAccept === 'function' && onAccept();
  };

  const _renderTermsLinks = terms => {
    const $terms = [];

    for (let i = 0; i < terms.length; i++) {
      const { label, link } = terms[i];

      $terms.push(
        <a key={label + ':' + link} className='terms-link' href={link} target='_blank'>
          View {label} <span>here</span>
        </a>
      );
    }

    return $terms;
  };

  const _renderParagraphs = (paragraphs = []) => {
    const $ps = [];

    for (let i = 0; i < paragraphs.length; i++) {
      const content = paragraphs[i];

      $ps.push(<p key={content}>{content}</p>);
    }

    return $ps;
  };

  return (
    <div className='form-terms'>
      <h1 className='title'>{title}</h1>

      <div className='doctor'>
        <div className='doctor__info'>
          <span className='doctor__label'>Practice Name:</span>
          <span className='doctor__value'>{doctor.name}</span>
        </div>

        <div className='doctor__info'>
          <span className='doctor__label'>Practice Number:</span>
          <span className='doctor__value'>{doctor.practiceNumber}</span>
        </div>

        <div className='doctor__info'>
          <span className='doctor__label'>Email Address:</span>
          <span className='doctor__value'>{doctor.email}</span>
        </div>

        <div className='doctor__info'>
          <span className='doctor__label'>Contact Number:</span>
          <span className='doctor__value'>{doctor.mobile}</span>
        </div>
      </div>

      <div className='paragraphs'>{_renderParagraphs(paragraphs)}</div>

      <div className='terms-links'>
        <div className='terms-links__leader'>By proceeding, you agree to the following terms and conditions:</div>

        {_renderTermsLinks(terms)}
      </div>

      <div className='actions'>
        <Button label='Accept and Proceed' onPress={_onAccept} />
      </div>
    </div>
  );
}

const scope = '.form-terms';
const styles = `
  {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 300px;
    max-width: 600px;
  }

  .title {
    width: 100%;
    margin: 0;
    padding: conf(spacing, smaller) 15px;
    font-preset: 1.5em light;
    text-align: left;
    color: conf(color, primary);
  }

  .doctor {
    display: flex;
    flex-direction: column;
    width: 100%
  }

  .doctor__info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: .5ch 15px
  }

  .doctor__label {
    color: conf(color, darkGrey);
    font-preset: .8em light;
  }

  .doctor__value {
    color: conf(color, darkGrey);
    font-preset: .8em normal;
  }
  
  .paragraphs {
    display: flex;
    flex-direction: column;
    padding: conf(spacing, smaller) 15px;
  }

  .paragraphs p {
    color: conf(color, black);
    font-preset: 1em light;
    line-height: 1.5;
    margin: .9ch 0;
  }

  .terms-links {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
  }

  .terms-links__leader {
    color: conf(color, primary);
    font-preset: 1em light;
    padding-bottom: .5em;
  }

  .terms-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: .3em 1em;
    margin-top: .5em;
    text-decoration: none;
    outline: none;
    font-preset: .8em light;
    color: conf(color, darkerGrey);
    cursor: pointer;
    transition: all .2s ease;
  }

  .terms-link span {
    margin-left: .5ch;
    color: conf(color, primary);
    border-bottom: 1px dotted conf(color, primary);
  }

  .terms-link::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background: conf(color, primary);
    opacity: .3;
    transition: all .2s ease;
  }

  .terms-link:hover {
    color: conf(color, black);
  }

  .terms-link:hover::before {
    opacity: 1;
    width: 3px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
  }
`;

CustomStyleSheet.create(styles, scope);
