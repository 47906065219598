import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import { FormField } from './form-field';

export function FieldSection({ config = {}, form, state } = {}) {
  const { title, fields } = config;

  const _renderFields = (fields = []) => {
    const $fields = [];

    for (let i = 0; i < fields.length; i++) {
      const config = fields[i];

      const $field = <FormField key={config.key} form={form} state={state} config={config} />;

      $fields.push($field);
    }

    return $fields;
  };

  return (
    <div className='form-section'>
      <div className='heading'>
        <h1>{title}</h1>
      </div>

      <div className='fields'>{_renderFields(fields)}</div>
    </div>
  );
}

const scope = '.form-section';
const styles = `
  {
    flexbox: column center initial;
    width: 100%;
  }

  .heading {
    position: sticky;
    top: 0;
    flexbox: column center center;
    padding: conf(spacing, small) 0;
    margin: 0;
    width: 100%;
    background: conf(color, opaqueWhite);
    z-index: 10;
  }

  .heading h1 {
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    margin: 0;
    padding: 0 15px;
    font-preset: 1.5em light;
    text-align: left;
    color: conf(color, primary);
  }

  .fields {
    flexbox: row center space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    padding: 0 15px;
  }
`;

CustomStyleSheet.create(styles, scope);
