import request from '@prahatech/util-react-web/http/request';
import hash from '@prahatech/util-react-web/utils/hash';

const ms_patient_url = '/drs-ms-patients';

const messages = {
  generalFailure: 'This service is currently unavailable. If this persists, please contact an administrator.',
  configFailure: 'A fatal error has occurred when attempting to load the application. Please contact an administrator.',
  unauthorized:
    'Provided form code is unrelated to the requested patient. Please obtain a code associated with this patient to interact with their data.',
};

export class PatientProvider {
  static async fetchFiles(patientEmail, formCode) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      files: undefined,
    };

    const response = await request({
      method: 'GET',
      url: `${ms_patient_url}/api/v1/patients/by-email/:email/files`,
      path: {
        email: patientEmail,
      },
      headers: {
        'Authorization': `FormCode ${formCode}`,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [response.status === 401 ? messages.unauthorized : messages.generalFailure, ...data.errors];

      return result;
    }

    const sortedAndHashedFiles = data.files.sort((a, b) =>
      new Date(a.updatedOn).getTime() < new Date(b.updatedOn).getTime() ? 1 : -1
    );

    for (let i = 0; i < sortedAndHashedFiles.length; i++) {
      const file = sortedAndHashedFiles[i];

      file.hash = hash(JSON.stringify(file));
    }

    result.ok = true;
    result.files = sortedAndHashedFiles;

    return result;
  }

  static async createFile(patientEmail, formCode, initialData = {}) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      file: undefined,
    };

    const response = await request({
      method: 'POST',
      url: `${ms_patient_url}/api/v1/patients/by-email/:email/files`,
      path: {
        email: patientEmail,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `FormCode ${formCode}`,
      },
      body: JSON.stringify(initialData),
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [response.status === 401 ? messages.unauthorized : messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;
    result.file = data.file;

    return result;
  }
}
