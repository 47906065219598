import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';
import { useState, useRef } from 'react';

const genName = () => Date.now() + '-+-' + Math.random() * 1000000;
const stringifyDate = (year, month, day, hour, minutes) => `${year}-${month}-${day}T${hour}:${minutes}:00.000`;

export function DateTimeInput(props) {
  const { onFieldChange, onFieldFocus, onFieldBlur, key, valid, value, required, error, showErrors, label } = props;

  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  const [year, setYear] = useState(undefined);
  const [month, setMonth] = useState(undefined);
  const [day, setDay] = useState(undefined);
  const [hour, setHour] = useState(undefined);
  const [minutes, setMinutes] = useState(undefined);

  const [uniqueFieldName, setUniqueFieldName] = useState(genName());

  useEffect(() => {
    setUniqueFieldName(genName());
  }, [key]);

  const yearRef = useRef(null);
  const monthRef = useRef(null);
  const dayRef = useRef(null);
  const hourRef = useRef(null);
  const minutesRef = useRef(null);

  const _clearInput = e => {
    setYear(undefined);
    setMonth(undefined);
    setDay(undefined);
    setHour(undefined);
    setMinutes(undefined);

    _changeValue(e, { year: undefined, month: undefined, day: undefined, hour: undefined, minutes: undefined });

    dayRef.current.focus();
  };

  const _changeYear = e => {
    const year = e.target.value;

    if (!/^\d{0,4}$/.test(year)) return;

    setYear(year);
    _changeValue(e, { year, month, day, hour, minutes });

    if (year.length >= 4) {
      hourRef.current.focus();
    }
  };

  const _changeMonth = e => {
    const month = e.target.value;

    if (!/^\d{0,2}$/.test(month)) return;

    setMonth(month);
    _changeValue(e, { year, month, day, hour, minutes });

    if (month.length >= 2) {
      yearRef.current.focus();
    }
  };

  const _changeDay = e => {
    const day = e.target.value;

    if (!/^\d{0,2}$/.test(day)) return;

    setDay(day);
    _changeValue(e, { year, month, day, hour, minutes });

    if (day.length >= 2) {
      monthRef.current.focus();
    }
  };

  const _changeHour = e => {
    const hour = e.target.value;

    if (!/^\d{0,2}$/.test(hour)) return;

    setHour(hour);
    _changeValue(e, { year, month, day, hour, minutes });

    if (hour.length >= 2) {
      minutesRef.current.focus();
    }
  };

  const _changeMinutes = e => {
    const minutes = e.target.value;

    if (!/^\d{0,2}$/.test(minutes)) return;

    setMinutes(minutes);
    _changeValue(e, { year, month, day, hour, minutes });
  };

  const _changeValue = (e, { year, month, day, hour, minutes }) => {
    if (!year || year.length !== 4 || !month || !day || !hour || !minutes) {
      onFieldChange(undefined);
      typeof props.onChange === 'function' && props.onChange(e);
      return;
    }

    year = String(year).padStart(4, 0);
    month = String(month).padStart(2, 0);
    day = String(day).padStart(2, 0);
    hour = String(hour).padStart(2, 0);
    minutes = String(minutes).padStart(2, 0);

    const dateStr = stringifyDate(year, month, day, hour, minutes);

    let date = new Date(dateStr);

    if (Number.isNaN(date.getTime())) date = undefined;

    onFieldChange(
      new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minutes))).toISOString()
    );
    typeof props.onChange === 'function' && props.onChange(e);
  };

  const _focus = e => {
    onFieldFocus();

    typeof props.onFocus === 'function' && props.onFocus(e);
  };

  const _blur = e => {
    onFieldBlur();

    typeof props.onBlur === 'function' && props.onBlur(e);

    setHasBeenTouched(true);
  };

  useEffect(() => {
    if (value === undefined || value === null) return;

    const date = Displayable.date(value);

    if (Number.isNaN(date.getTime())) return;

    const yyyy = date.utc.year.full;
    const mm = date.utc.month.full;
    const dd = date.utc.day.full;
    const hh = date.utc.hour.full;
    const MM = date.utc.minutes.full;

    const currentDateString = stringifyDate(year, month, day, hour, minutes);
    const newDateString = stringifyDate(yyyy, mm, dd, hh, MM);

    if (newDateString === currentDateString) return;

    if (Number(yyyy) !== Number(year)) setYear(yyyy);
    if (Number(mm) !== Number(month)) setMonth(mm);
    if (Number(dd) !== Number(day)) setDay(dd);
    if (Number(hh) !== Number(hour)) setHour(hh);
    if (Number(MM) !== Number(minutes)) setMinutes(MM);
  }, [value]);

  const shouldShowErrors = showErrors && hasBeenTouched && (value || required) && error;

  return (
    <div className='datetime-input'>
      <div className='bqi__label'>
        {label}
        {required ? <span className='bqi__label__required'>*</span> : ''}
      </div>
      <div className='date-inputs'>
        <input
          type='text'
          name={uniqueFieldName + '-dd'}
          autoComplete='new-password'
          className='input_day'
          placeholder='dd'
          value={day || ''}
          ref={dayRef}
          onChange={_changeDay}
          onFocus={_focus}
          onBlur={_blur}
        />
        <span className='dot'>•</span>
        <input
          type='text'
          name={uniqueFieldName + '-mm'}
          autoComplete='new-password'
          className='input_month'
          placeholder='mm'
          value={month || ''}
          ref={monthRef}
          onChange={_changeMonth}
          onFocus={_focus}
          onBlur={_blur}
        />
        <span className='dot'>•</span>
        <input
          type='text'
          name={uniqueFieldName + '-yyyy'}
          autoComplete='new-password'
          className='input_year'
          placeholder='yyyy'
          value={year || ''}
          ref={yearRef}
          onChange={_changeYear}
          onFocus={_focus}
          onBlur={_blur}
        />
        <input
          type='text'
          name={uniqueFieldName + '-hh'}
          autoComplete='new-password'
          className='input_hour'
          placeholder='hh'
          value={hour || ''}
          ref={hourRef}
          onChange={_changeHour}
          onFocus={_focus}
          onBlur={_blur}
        />
        <span className='colon'>:</span>
        <input
          type='text'
          name={uniqueFieldName + '-MM'}
          autoComplete='new-password'
          className='input_minutes'
          placeholder='mm'
          value={minutes || ''}
          ref={minutesRef}
          onChange={_changeMinutes}
          onFocus={_focus}
          onBlur={_blur}
        />
        {year || month || day || hour || minutes ? (
          <div className='core-date-input__clear-btn' onClick={_clearInput}>
            <svg className='core-date-input__clear-icon' viewBox='0 0 102 102' xmlns='http://www.w3.org/2000/svg'>
              <path d='M51 102C79.1665 102 102 79.1665 102 51C102 22.8335 79.1665 0 51 0C22.8335 0 0 22.8335 0 51C0 79.1665 22.8335 102 51 102ZM57.364 51L72.182 65.818C73.9393 67.5754 73.9393 70.4246 72.182 72.182C70.4246 73.9393 67.5754 73.9393 65.818 72.182L51 57.364L36.182 72.182C34.4246 73.9393 31.5754 73.9393 29.818 72.182C28.0607 70.4246 28.0607 67.5754 29.818 65.818L44.636 51L29.818 36.182C28.0607 34.4246 28.0607 31.5754 29.818 29.818C31.5754 28.0607 34.4246 28.0607 36.182 29.818L51 44.636L65.818 29.818C67.5754 28.0607 70.4246 28.0607 72.182 29.818C73.9393 31.5754 73.9393 34.4246 72.182 36.182L57.364 51Z' />
            </svg>
          </div>
        ) : null}
      </div>
      <div className='bqi__error'>{shouldShowErrors && <span>{error}</span>}</div>
    </div>
  );
}

const scope = '.datetime-input';
const styles = `
  {
    width: 100%;
    margin: 0 0 conf(spacing, small) 0;
    display: flex;
    flex-direction: column;
  }

  .bqi__label {
    font-preset: 1em light;
    color: conf(color, primary);
  }

  .bqi__label__required {
    color: conf(color, warn);
    padding: 0 2px;
  }

  .bqi__error {
    padding-top: 4px;
    font-preset: .8em light;
    color: conf(color, warn);
  }

  .date-inputs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  input {
    width: 7ch;
    text-align: center;
    padding: conf(spacing, smaller) 0 conf(spacing, smaller) 0;
    color: conf(color, darkerGrey);
    outline: none;
    border: none;
    border-bottom: 2px solid conf(color, lightGrey);
  }

  input:focus {
    border-bottom: 2px solid conf(color, primary);
  }

  input::placeholder {
    color: conf(color, grey);
  }

  .dot {
    color: conf(color, lightGrey);
    padding-top: 7px;
    display: flex;
    align-items: center;
  }

  .colon {
    color: conf(color, lightGrey);
    padding-top: 7px;
    display: flex;
    align-items: center;
  }

  .input_year {
    margin-right: 20px;
  }


  .input_minutes {
    margin-right: 10px;
  }

  .core-date-input__clear-btn {
    align-self: center;
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .core-date-input__clear-icon {
    fill: conf(color, lightGrey);
    height: 80%;
    width: 80%;
  }

  .core-date-input__clear-btn:hover .core-date-input__clear-icon {
    fill: conf(color, warn);
  }
`;

CustomStyleSheet.create(styles, scope);

export default DateTimeInput;
