import request from '@prahatech/util-react-web/http/request';

const ms_payments_url = '/drs-ms-payments';

const messages = {
  generalFailure: 'This service is currently unavailable. If this persists, please contact an administrator.',
};

export class PaymentProvider {
  static async fetchPayment(formCode) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      payment: undefined,
    };

    const response = await request({
      method: 'GET',
      url: `${ms_payments_url}/api/v1/payments/by-form-code/:formCode`,
      path: {
        formCode: formCode,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;
    result.payment = data.payment;

    return result;
  }

  static async fetchPaymentStatus(formCode) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      payment: undefined,
    };

    const response = await request({
      method: 'GET',
      url: `${ms_payments_url}/api/v1/payments/by-form-code/:formCode/status`,
      path: {
        formCode: formCode,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;
    result.payment = data.payment;

    return result;
  }
}
