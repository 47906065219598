import { NotExistScreen } from '../screens/not-exist/not-exist';
import { FormScreen } from '../screens/form/form';
import {
  ThankYouScreen,
  PaymentErrorScreen,
  ExpiredScreen,
  KaboomKapowScreen,
  NotFoundScreen,
} from '../screens/form/feedback';

export const routes = {
  '/': {
    screen: NotExistScreen,
  },
  '/form/:code': {
    screen: FormScreen,
  },
  '/form/feedback/thank-you': {
    screen: ThankYouScreen,
  },
  '/form/feedback/payment-error': {
    screen: PaymentErrorScreen,
  },
  '/form/feedback/not-found': {
    screen: NotFoundScreen,
  },
  '/form/feedback/expired': {
    screen: ExpiredScreen,
  },
  '/form/feedback/kaboom-kapow': {
    screen: KaboomKapowScreen,
  },
};

export default routes;
