import { useState, useEffect } from 'react';
import { useForm } from '@prahatech/util-react-web/form/useForm';

import Displayable from '@prahatech/util-react-web/utils/Displayable';
import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

import { navigator } from '../../navigation/app-router';

import { FormProvider, PatientProvider } from '../../providers';

import { Button, Spinner } from '../../core/components';

import { Terms } from './components/terms';

import { validateForm } from './helpers';
import { FieldSection } from './components/field-section';
import { PaymentSection } from './components/payment-section';

const noop = e => e && e.preventDefault && e.preventDefault();

export function FormScreen({ params }) {
  const code = params.code;

  const UIForm = useForm(state => {
    setFormState(state);
    UIForm.showErrors();
  });

  const [formState, setFormState] = useState(UIForm.getState());

  UIForm._.globalValidate = () => {
    if (isSettingUp || !form || !form.config.sections) return;

    const state = UIForm.getState();

    const result = validateForm(currentSection, form.config.sections, state);

    return result.errors;
  };

  const [isSettingUp, setIsSettingUp] = useState(true);
  const [isLoadingForwards, setIsLoadingForwards] = useState(false);
  const [isLoadingBackwards, setIsLoadingBackwards] = useState(false);
  let [isProcessingPayment, setIsProcessingPayment] = useState(false);
  // isProcessingPayment = true;
  const [showGenericError, setShowGenericError] = useState(false);

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const [form, setForm] = useState(undefined);
  const [currentSection, setCurrentSection] = useState(0);

  const [patientFiles, setPatientFiles] = useState([]);
  const [activePatientFile, setActivePatientFile] = useState(undefined);
  const [createNewFile, setCreateNewFile] = useState(false);

  const hasPayment = form && form.payment && form.payment.required;

  const _acceptTerms = () => {
    setHasAcceptedTerms(true);

    if (form.status.toLowerCase() === 'paying') {
      setIsProcessingPayment(true);

      return;
    }
  };

  const _showGenericError = () => {
    setShowGenericError(true);
  };

  const _dismissGenericError = () => {
    setShowGenericError(false);
  };

  const _deactivatePatientFile = () => {
    setCreateNewFile(false);
    setActivePatientFile(undefined);
  };

  const _activateNewPatientFile = () => {
    setCreateNewFile(true);
    setActivePatientFile(undefined);

    UIForm.setValues({});

    setFormState(UIForm.getState());
  };

  const _activatePatientFile = async file => {
    setIsSettingUp(true);

    const dataFetchResult = await FormProvider.fetchPatientFileData(code, file.uid);

    if (!dataFetchResult.ok) {
      setShowGenericError(false);
      setIsSettingUp(false);

      return;
    }

    const fileData = dataFetchResult.data;

    UIForm.setValues(fileData || {});

    setFormState(UIForm.getState());
    setActivePatientFile(file);
    setCurrentSection(0);
    setIsSettingUp(false);
  };

  const _save = async () => {
    let file = activePatientFile;
    const data = formState.values;

    if (!file) {
      const minData = {
        firstname: data.firstname,
        surname: data.surname,
        dob: data.dob,
      };

      const fileCreationResult = await PatientProvider.createFile(form.patient.email, code, minData);

      if (!fileCreationResult.ok) return false;

      file = fileCreationResult.file;

      const newPatientFilesResult = await PatientProvider.fetchFiles(form.patient.email, code);

      if (!newPatientFilesResult.ok || !newPatientFilesResult.files || !newPatientFilesResult.files.length) {
        navigator.navigate(`/form/feedback/kaboom-kapow`);

        return;
      }

      const newFiles = newPatientFilesResult.files;

      for (let i = 0; i < newFiles.length; i++) {
        const newFile = newFiles[i];

        if (Number(newFile.uid) === Number(file.uid)) {
          file = newFile;
          break;
        }
      }

      setPatientFiles(newFiles);
      setActivePatientFile(file);
    }

    const dataUpdateResult = await FormProvider.updatePatientFileData(code, file.uid, data);

    if (!dataUpdateResult.ok) return false;

    return true;
  };

  const _nextSection = async () => {
    setIsLoadingForwards(true);

    const saved = await _save();

    if (!saved) {
      setShowGenericError(true);
      setIsLoadingForwards(false);

      return;
    }

    const wasLastSection = currentSection === form.config.sections.length - 1;

    if (wasLastSection) {
      if (hasPayment) {
        const payingResult = await FormProvider.updateFormStatus(code, 'paying');

        if (!payingResult.ok) {
          setShowGenericError(true);
          setIsLoadingForwards(false);
        }

        setIsProcessingPayment(true);

        return;
      }

      const completionResult = await FormProvider.updateFormStatus(code, 'completed');

      if (!completionResult.ok) {
        setShowGenericError(true);
        setIsLoadingForwards(false);

        return;
      }

      navigator.navigate('/form/feedback/thank-you');

      return;
    }

    setCurrentSection(currentSection + 1);
    setIsLoadingForwards(false);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const _previousSection = async () => {
    setIsLoadingBackwards(true);

    const saved = await _save();

    if (!saved) {
      setShowGenericError(true);
      setIsLoadingBackwards(false);

      return;
    }

    setCurrentSection(currentSection - 1);
    setIsLoadingBackwards(false);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const _onPaymentCompleted = () => {
    navigator.navigate(`/form/feedback/thank-you`);
  };

  useEffect(() => {
    setIsSettingUp(true);
    setFormState(undefined);
    setCurrentSection(0);
    setShowGenericError(false);
    setHasAcceptedTerms(false);
    setIsProcessingPayment(false);

    setForm(undefined);
    setPatientFiles([]);
    setActivePatientFile(undefined);
    setCreateNewFile(false);

    document.title = 'docpay';

    Promise.resolve()
      .then(() => FormProvider.fetchForm(code))
      .then(result => {
        if (!result.ok) {
          switch (result.status) {
            case 423:
              navigator.navigate(`/form/feedback/thank-you`);
              break;

            case 410:
              navigator.navigate(`/form/feedback/expired`);
              break;

            case 404:
              navigator.navigate(`/form/feedback/not-found`);
              break;

            default:
              navigator.navigate(`/form/feedback/kaboom-kapow`);
          }

          return;
        }

        const form = result.form;

        document.title = `${form.doctor.label} - ${form.config.title}`;

        if (form.status.toLowerCase() === 'created') FormProvider.updateFormStatus(code, 'viewed');

        UIForm.setValues(form.data || {});

        setFormState(UIForm.getState());
        setForm(form);

        return form;
      })
      .then(form => {
        if (!form) return;

        if (form.status.toLowerCase() === 'paying') {
          setIsSettingUp(false);

          return;
        }

        return PatientProvider.fetchFiles(form.patient.email, code);
      })
      .then(result => {
        if (!result) return;

        if (!result.ok) {
          navigator.navigate(`/form/feedback/kaboom-kapow`);

          return;
        }

        const files = result.files;

        setPatientFiles(files);
        setIsSettingUp(false);
      })
      .catch(e => {
        console.error(e);

        navigator.navigate(`/form/feedback/kaboom-kapow`);
      });
  }, [code]);

  const _renderPatientFiles = (files = []) => {
    const $files = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { hash, updatedOn } = file;
      const { firstname, surname, dob } = file.data;

      const dobDate = Displayable.date(dob);
      const dobString = `${dobDate.day.full}-${dobDate.month.full}-${dobDate.year.full}`;

      const updatedDate = Displayable.date(updatedOn);
      const updated = `${updatedDate.day.full} ${updatedDate.month.long}, ${updatedDate.year.full}`;

      $files.push(
        <div key={hash} className='patient-file-selector__file' onClick={() => _activatePatientFile(file)}>
          <div className='patient-file-selector__file-icon'>
            <svg
              className='patient-file-selector__file-icon-svg'
              viewBox='0 0 247 200'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path d='M3 199.39H214.38C214.752 199.382 215.119 199.301 215.46 199.15L215.76 198.97C215.983 198.879 216.187 198.747 216.36 198.58L216.63 198.28C216.783 198.125 216.906 197.941 216.99 197.74C217.062 197.616 217.123 197.485 217.17 197.35V197.08L246 79.33C246.108 78.8917 246.115 78.4348 246.021 77.9933C245.928 77.5518 245.736 77.137 245.46 76.78C245.177 76.4225 244.817 76.134 244.407 75.9365C243.996 75.7389 243.546 75.6375 243.09 75.64H217.38V41.14C217.38 40.3443 217.064 39.5813 216.501 39.0187C215.939 38.4561 215.176 38.14 214.38 38.14H95.04L80.79 2.5C80.563 1.94741 80.1762 1.47518 79.6791 1.14381C79.1821 0.812435 78.5974 0.637003 78 0.639995H3C2.20435 0.639995 1.44129 0.956069 0.87868 1.51868C0.316071 2.08129 0 2.84435 0 3.63999V196.39C0 197.186 0.316071 197.949 0.87868 198.511C1.44129 199.074 2.20435 199.39 3 199.39ZM217.38 81.64H239.19L217.38 171.34V81.64ZM6 6.64H75.96L90.21 42.25C90.4323 42.8082 90.8171 43.2868 91.3145 43.6238C91.812 43.9607 92.3992 44.1406 93 44.14H211.38V193.39H6V6.64Z' />
              <path d='M159.84 98.83H127.2V66.19C127.2 65.3944 126.884 64.6313 126.321 64.0687C125.759 63.5061 124.996 63.19 124.2 63.19H91.8C91.0043 63.19 90.2413 63.5061 89.6787 64.0687C89.1161 64.6313 88.8 65.3944 88.8 66.19V98.83H56.16C55.3643 98.83 54.6013 99.1461 54.0387 99.7087C53.4761 100.271 53.16 101.034 53.16 101.83V134.23C53.16 135.026 53.4761 135.789 54.0387 136.351C54.6013 136.914 55.3643 137.23 56.16 137.23H88.8V169.87C88.8 170.666 89.1161 171.429 89.6787 171.991C90.2413 172.554 91.0043 172.87 91.8 172.87H124.2C124.996 172.87 125.759 172.554 126.321 171.991C126.884 171.429 127.2 170.666 127.2 169.87V137.23H159.84C160.636 137.23 161.399 136.914 161.961 136.351C162.524 135.789 162.84 135.026 162.84 134.23V101.83C162.84 101.034 162.524 100.271 161.961 99.7087C161.399 99.1461 160.636 98.83 159.84 98.83ZM156.84 131.23H124.2C123.404 131.23 122.641 131.546 122.079 132.109C121.516 132.671 121.2 133.434 121.2 134.23V166.87H94.8V134.23C94.8 133.434 94.4839 132.671 93.9213 132.109C93.3587 131.546 92.5956 131.23 91.8 131.23H59.16V104.83H91.8C92.5956 104.83 93.3587 104.514 93.9213 103.951C94.4839 103.389 94.8 102.626 94.8 101.83V69.19H121.2V101.83C121.2 102.626 121.516 103.389 122.079 103.951C122.641 104.514 123.404 104.83 124.2 104.83H156.84V131.23Z' />
            </svg>
          </div>

          <div className='patient-file-selector__file-detail'>
            <span className='patient-file-selector__file-label'>
              {firstname[0].toUpperCase()}. {surname} <span>({dobString})</span>
            </span>

            <span className='patient-file-selector__file-updated'>Last updated on {updated}</span>
          </div>
        </div>
      );
    }

    $files.push(
      <div key='use-new-file' className='patient-file-selector__file' onClick={_activateNewPatientFile}>
        <div className='patient-file-selector__file-icon'>
          <svg
            className='patient-file-selector__file-icon-svg-plus'
            viewBox='0 0 30 30'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='M15 0.857864V15M15 15V29.1421M15 15H0.857872M15 15H29.1421' />
          </svg>
        </div>

        <div className='patient-file-selector__file-detail'>
          <span className='patient-file-selector__file-label' style={{ paddingBottom: 0 }}>
            Someone else
          </span>
        </div>
      </div>
    );

    return $files;
  };

  const isLastFormSection = form && currentSection === form.config.sections.length - 1;
  const paymentAmountCents = hasPayment ? form.payment.amount : undefined;

  return (
    <div className='form-screen'>
      {isSettingUp ? (
        <div className='form-screen__spinner'>
          <Spinner size='80px' thickness='5px' />
        </div>
      ) : showGenericError ? (
        <div className='generic-error'>
          <h1 className='generic-error__title'>Unexpected Error</h1>

          <p className='generic-error__detail'>
            An unexpected error has occurred. Please try again or if the issue persists, give our engineers some time to
            investigate.
          </p>

          <div className='generic-error__button'>
            <Button label='Okay' onPress={_dismissGenericError} />
          </div>
        </div>
      ) : !hasAcceptedTerms ? (
        <div className='term-container'>
          <Terms config={form.config.terms} onAccept={_acceptTerms} />
        </div>
      ) : !createNewFile && !activePatientFile && patientFiles && patientFiles.length ? (
        <div className='patient-file-selector'>
          <h1 className='patient-file-selector__heading'>Select the patient</h1>

          <div className='patient-file-selector__files'>{_renderPatientFiles(patientFiles)}</div>
        </div>
      ) : (
        <div className='form'>
          {!isProcessingPayment ? (
            <form onSubmit={noop} autoComplete='off' className='form__sections'>
              <FieldSection form={UIForm} state={formState} config={form.config.sections[currentSection]} />

              <div className='form__actions'>
                {currentSection !== 0 && (
                  <div className='form__action'>
                    <span className='form__action-prefix' />

                    <Button
                      secondary
                      label='Back'
                      onPress={_previousSection}
                      loading={isLoadingBackwards}
                      disabled={isLoadingForwards}
                    />
                  </div>
                )}

                <div className='form__action'>
                  <span className='form__action-prefix' />

                  <Button
                    label={isLastFormSection ? 'Submit' : 'Next'}
                    onPress={_nextSection}
                    loading={isLoadingForwards}
                    disabled={!formState.valid || isLoadingBackwards}
                  />
                </div>
              </div>

              {!formState.valid && (
                <div className='form__progression-warning'>Complete all questions in order to continue.</div>
              )}
            </form>
          ) : (
            <PaymentSection
              formCode={code}
              heading={form.payment.heading}
              motivation={form.payment.motivation}
              termsLink={form.payment.terms}
              amountCents={paymentAmountCents}
              onComplete={_onPaymentCompleted}
              onError={_showGenericError}
            />
          )}
        </div>
      )}
    </div>
  );
}

const scope = '.form-screen';
const styles = `
  {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 350px;
  }

  .form-screen__spinner {
    flexbox: column center center;
    width: 100%;
    height: 100%;
  }

  .generic-error {
    flexbox: column center center;
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  .generic-error__title {
    color: conf(color, warn);
    margin: 0;
    font-preset: 2em light;
    text-align: center;
    padding-bottom: conf(spacing, smallest);
  }

  .generic-error__detail {
    color: conf(color, darkerGrey);
    font-preset: 1em light;
    text-align: center;
    margin: 0;
  }
  
  .generic-error__button {
    width: 150px;
  }

  .term-container {
    flexbox: column center initial;
    padding: 0 15px;
    width: 100%;
  }

  .patient-file-selector {
    flexbox: column center initial;
    padding: 0 15px;
    width: 100%;
  }
  
  .patient-file-selector__heading {
    margin: 0;
    padding: conf(spacing, small) 0 conf(spacing, smallest) 0;
    font-preset: 1.5em light;
    text-align: left;
    color: conf(color, primary);
    width: 100%;
    max-width: 600px;
  }

  .patient-file-selector__files {
    flexbox: column center flex-start;
    width: 100%;
    max-width: 600px;
  }

  .patient-file-selector__file {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: conf(spacing, smaller) 0;
    border-bottom: 1px solid conf(color, lightGrey);
    cursor: pointer;
  }

  .patient-file-selector__file-icon {
    height: 60px;
    width: 60px;
    padding: 10px;
    padding-right: 20px;
  }

  .patient-file-selector__file-icon-svg {
    width: 100%;
    height: 100%;
    fill: conf(color, primary);
  }

  .patient-file-selector__file-icon-svg-plus {
    width: 100%;
    height: 100%;
    stroke: conf(color, primary);
    stroke-width: 2px
  }

  .patient-file-selector__file-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .patient-file-selector__file-updated {

  }

  .patient-file-selector__file-label {
    font-preset: 1em light;
    color: conf(color, primary);
    padding-bottom: conf(spacing, smallest);
  }

  .patient-file-selector__file-label span {
    font-preset: .7em light;
    color: conf(color, grey);
    padding-bottom: conf(spacing, smallest);
  }
  
  .patient-file-selector__file-updated {
    font-preset: .7em light;
    color: conf(color, grey);
  }

  .form {
    position: relative;
    width: 100%;
  }

  .patient-files-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 20px;
    right: 15px;
    z-index: 20; 
    cursor: pointer;
  }

  .patient-files-btn__icon {
    width: 2em;
    height: 2em;
    stroke: none;
    fill: conf(color, primary);
  }

  .patient-files-btn__label {
    font-preset: .8em light;
    color: conf(color, primary);
  }

  .form__sections {

  }
  
  .form-section {
    flexbox: column center initial;
    width: 100%;
  }

  .form-section--inactive {
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .form-section__heading {
    position: sticky;
    top: 0;
    flexbox: column center center;
    padding: conf(spacing, small) 0;
    margin: 0;
    width: 100%;
    background: conf(color, opaqueWhite);
    z-index: 10;
  }

  .form-section__heading h1 {
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    margin: 0;
    padding: 0 15px;
    font-preset: 1.5em light;
    text-align: left;
    color: conf(color, primary);
  }

  .form-section__fields {
    flexbox: row center space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    padding: 0 15px;
  }

  .form-section__field {
    width: 100%;
  }

  .form-section__field--inactive {
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .form__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    margin: 0 auto;
  }

  .form__action {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 0 15px;
  }

  .form__action-prefix {
    display: block;
    color: conf(color, primary);
    font-preset: .8em light;
    width: 100%;
    text-align: right;
  }

  .form__action .core-button {
    margin-top: .5em;
  }

  .form__progression-warning {
    color: conf(color, warn);
    font-preset: .8em light;
    width: 100%;
    text-align: center;
    padding-bottom: 5ch;
  }
`;

CustomStyleSheet.create(styles, scope);
