import request from '@prahatech/util-react-web/http/request';
import hash from '@prahatech/util-react-web/utils/hash';

const ms_forms_url = '/drs-ms-forms';

const messages = {
  generalFailure: 'This service is currently unavailable. If this persists, please contact an administrator.',
  expiredForm: 'This service is currently unavailable. If this persists, please contact an administrator.',
  completedForm: 'This form has already been completed and can not be viewed or changed anymore.',
  configFailure: 'A fatal error has occurred when attempting to load the application. Please contact an administrator.',
  unauthorized: 'User authentication could not be verified. Please sign in.',
};

export class FormProvider {
  static async fetchForm(code) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      form: undefined,
    };

    const response = await request({
      method: 'GET',
      url: `${ms_forms_url}/api/v1/forms/by-code/:code`,
      path: {
        code: code,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok && response.status === 410) {
      result.errors = [messages.expiredForm, ...data.errors];

      return result;
    }

    if (!response.ok && response.status === 423) {
      result.errors = [messages.completedForm, ...data.errors];

      return result;
    }

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;
    result.form = data.form;

    for (let i = 0; i < result.form.config.sections.length; i++) {
      const section = result.form.config.sections[i];

      section.hash = hash(JSON.stringify(section));

      for (let j = 0; j < section.fields.length; j++) {
        const field = section.fields[j];
        const { key, label, required } = field;

        if (!key) field.key = 'unkeyed-unique-' + hash(field);

        const configWhen = field.when ? eval(field.when) : undefined;
        const configValidate = field.validate ? eval(field.validate) : undefined;

        const when = state => !configWhen || configWhen(state);

        const validate = state => {
          const isActive = when(state);

          if (!isActive) return true;

          const values = (state && state.values) || {};
          const value = state && state.values ? state.values[key] : undefined;

          const hasValue = !(value === undefined || value === null || value === '');

          if (!required && !hasValue) return true;

          if (required && !hasValue) return `${label} is required.`;

          return !configValidate || configValidate(value, values, state);
        };

        field.when = when;
        field.validate = validate;
      }
    }

    return result;
  }

  static async fetchPatientFileData(code, patientFileUid) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      data: undefined,
    };

    const response = await request({
      method: 'GET',
      url: `${ms_forms_url}/api/v1/forms/by-code/:code/file/:fileUid`,
      path: {
        code: code,
        fileUid: patientFileUid,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;
    result.data = data.file.data;

    return result;
  }

  static async updatePatientFileData(code, patientFileUid, update = {}) {
    const result = {
      ok: false,
      status: 400,
      errors: [],
      data: undefined,
    };

    const response = await request({
      method: 'PATCH',
      url: `${ms_forms_url}/api/v1/forms/by-code/:code/file/:fileUid`,
      path: {
        code: code,
        fileUid: patientFileUid,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(update),
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;
    result.data = data.data;

    return result;
  }

  static async updateFormStatus(code, targetStatus = 'viewed') {
    const result = {
      ok: false,
      status: 400,
      errors: [],
    };

    if (!targetStatus) {
      result.ok = true;
      result.status = 0;

      return result;
    }

    targetStatus = targetStatus.toLowerCase();

    if (!['viewed', 'paying', 'completed'].includes(targetStatus)) {
      result.errors = [`The target status code provided isn't allowed.`, targetStatus];

      return result;
    }

    const response = await request({
      method: 'POST',
      url: `${ms_forms_url}/api/v1/forms/by-code/:code/status/:targetStatus`,
      path: {
        code: code,
        targetStatus: targetStatus,
      },
    });

    result.status = response.status;

    const data = response.body ? JSON.parse(response.body) : { errors: [] };

    if (!response.ok || !data.ok) {
      result.errors = [messages.generalFailure, ...data.errors];

      return result;
    }

    result.ok = true;

    return result;
  }
}
