import { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';

import CustomStyleSheet from '@prahatech/util-react-web/stylesheet/CustomStyleSheet';

export function RawHTML(props) {
  const { html } = props;

  const [cleanHtml, setCleanHtml] = useState(undefined);

  useEffect(() => {
    if (!html) {
      setCleanHtml(undefined);
      return;
    }

    const cleanedHtml = DOMPurify.sanitize(html);

    setCleanHtml(cleanedHtml);
  });

  return !cleanHtml ? null : <div className='core-raw-html' dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
}

const scope = '.core-raw-html';
const styles = `
  {
    width: 100%;
    font-preset: light;
    color: conf(color, darkGrey);
    margin: 0 0 conf(spacing, small) 0;
  }
`;

CustomStyleSheet.create(styles, scope);
